/**
 * NOTE: Font files themselves are primarily served from pl-marketing S3 bucket,
 * excluding the google material icons, which come from google's CDN.
 * For all fonts, however, there is a locally supplied fallback,
 * should any CDN become inaccessible.
 */


@font-face {
  font-family: "ProximaNova";
  font-weight: 700;
  src:
    url("/fonts/proxima-nova/ProximaNova-Semibold.woff") format("woff"),
    url("/fonts/proxima-nova/ProximaNova-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "ProximaNova";
  font-weight: 400;
  src:
    url("/fonts/proxima-nova/ProximaNova-Regular.woff") format("woff"),
    url("/fonts/proxima-nova/ProximaNova-Regular.ttf") format("truetype");
}

$proximaNova: "ProximaNova", Helvetica, sans-serif;

html,
button,
input,
select,
textarea {
  font-family: $proximaNova;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $proximaNova;
}

.strong {
  font-weight: bold;
}

.medium {
  font-weight: 500 !important;
}

.light {
  font-weight: 100 !important;
}

.em {
  font-style: italic;
}
