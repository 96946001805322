@font-face {
	font-family: "ProximaNova-Regular";
	font-style: normal;
	font-weight: 400;
	src:
		url("./fonts/proxima-nova/ProximaNova-Regular.woff") format("woff"),
		url("./fonts/proxima-nova/ProximaNova-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "ProximaNova-Semibold";
	font-style: normal;
	font-weight: normal;
	src:
		url("./fonts/proxima-nova/ProximaNova-Semibold.woff") format("woff"),
		url("./fonts/proxima-nova/ProximaNova-Semibold.ttf") format("truetype");
}

/* Simplified Chinese */
@font-face {
	font-family: "NotoSansSC-Regular";
	font-style: normal;
	font-weight: 400;
	src:
		url("./fonts/noto-sans/NotoSansSC-Regular.otf") format("opentype"),
		url("./fonts/noto-sans/NotoSansSC-Regular.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansSC-Regular.ttf") format("truetype"),
		url("./fonts/noto-sans/NotoSansSC-Regular.svg") format("svg");
}

@font-face {
	font-family: "NotoSansSC-Medium";
	font-style: normal;
	font-weight: normal;
	src:
		url("./fonts/noto-sans/NotoSansSC-Medium.otf") format("opentype"),
		url("./fonts/noto-sans/NotoSansSC-Medium.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansSC-Medium.ttf") format("truetype"),
		url("./fonts/noto-sans/NotoSansSC-Medium.svg") format("svg");
}

/* Arabic */
@font-face {
	font-family: "NotoSansArabic-Regular";
	font-style: normal;
	font-weight: 400;
	src:
		url("./fonts/noto-sans/NotoSansArabic-Regular.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansArabic-Regular.ttf") format("truetype"),
		url("./fonts/noto-sans/NotoSansArabic-Regular.svg") format("svg");
}

@font-face {
	font-family: "NotoSansArabic-SemiBold";
	font-style: normal;
	font-weight: normal;
	src:
		url("./fonts/noto-sans/NotoSansArabic-SemiBold.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansArabic-SemiBold.ttf") format("truetype"),
		url("./fonts/noto-sans/NotoSansArabic-SemiBold.svg") format("svg");
}

/* Korean */
@font-face {
	font-family: "NotoSansKR-Regular";
	font-style: normal;
	font-weight: 400;
	src:
		url("./fonts/noto-sans/NotoSansKR-Regular.otf") format("opentype"),
		url("./fonts/noto-sans/NotoSansKR-Regular.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansKR-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "NotoSansKR-Medium";
	font-style: normal;
	font-weight: normal;
	src:
		url("./fonts/noto-sans/NotoSansKR-Medium.otf") format("opentype"),
		url("./fonts/noto-sans/NotoSansKR-Medium.woff") format("woff"),
		url("./fonts/noto-sans/NotoSansKR-Medium.ttf") format("truetype");
}
