table {
	@include font-size(0.875);
	margin-top: 0.5625rem;
	border: 1px solid $lightgrey;
	&:first-child{
		margin-top: 0;
	}
}

caption {
	@include font-size(1);
	padding-bottom: 1rem;
	text-align: left;
}

tfoot {
	background: $lightgrey;
}

tr {
	border-top: 1px solid $lightgrey;
	&:first-child {
		border-top: 0;
	}
}

td {
	@include font-size(0.875);
	padding: 1rem;
}

th {
	@include font-size(1);
	padding: 1rem;
	font-weight: bold;
}
