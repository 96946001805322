body {
	font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: bold;
	color: $black;
}

h1,
.h1 {
	@include font-size(2);
	margin-top: 27px;
	line-height: 2rem;
	&:first-child{
		margin-top: 0;
	}
}

h2,
.h2 {
	@include font-size(1.25);
	margin-top: 0.9375rem;
	&:first-child{
		margin-top: 0;
	}
}

h3,
.h3 {
	@include font-size(1.125);
	margin-top: 0.875rem;
	&:first-child{
		margin-top: 0;
	}
}

h4,
.h4 {
	@include font-size(1);
	margin-top: 0.75rem;
	&:first-child{
		margin-top: 0;
	}
}

h5,
.h5 {
	@include font-size(0.875);
	margin-top: 0.6875rem;
	&:first-child{
		margin-top: 0;
	}
}

h6,
.h6 {
	@include font-size(0.75);
	margin-top: 0.625rem;
	&:first-child{
		margin-top: 0;
	}
}

@media screen and (max-width: $smallScreen) {

	h1,
	.h1 {
		@include font-size(1.5);
		margin-top: 0.9375rem;
	}

	h2,
	.h2 {
		@include font-size(1.375);
		margin-top: 0.875rem;
		margin-left: 18px;
		margin-right: 18px;
	}

	h3,
	.h3 {
		@include font-size(1.25);
		margin-top: 0.75rem;
	}

	h4,
	.h4 {
		@include font-size(1.125);
		margin-top: 0.6875rem;
	}

	h5,
	.h5 {
		@include font-size(1.0625);
		margin-top: 0.6875rem;
	}

	h6,
	.h6 {
		@include font-size(1);
		margin-top: 0.625rem;
	}

}
