a {
	color: $darkblue;
	text-decoration: none;
	&:hover {
		text-decoration: underline;
	}
	&:focus {
		outline: none;
	}
}

em {
	font-style: italic;
}

strong {
	font-weight: bold;
}

small {
	font-size: 62.5%;
}

s {
	text-decoration: line-through;
}

abbr {
	font-variant: small-caps;
}

sup {
	position: relative;
	top: -0.625rem;
	font-size: 70%;
}

sub {
	position: relative;
	bottom: -0.625rem;
	font-size: 70%;
}

b {
	font-weight: bold;
}

u {
	text-decoration: underline;
}

mark {
	background: $highlight;
}
