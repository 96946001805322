form {
	margin-top: 1.25rem;
	&:first-child {
		margin-top: 0;
	}
}

fieldset {
	display: block;
	box-sizing: border-box;
	border: 0;
	border-bottom: 1px solid $grey;
	padding: 1rem;
}

legend {
	@include font-size(0.875);
}

label {
	@include font-size(0.875);
	font-weight: 500;
	box-sizing: border-box;
}

input {
	box-sizing: border-box;
	@include font-size(1);
	outline: none;
	border: 0;
	border-bottom: 2px solid $grey;
	padding: 0.25rem 0.5rem;
	border-radius: 0;
	margin-left: 0;
	transition: border 0.2s ease-in-out;
	&:focus {
		outline: none;
		border-color: $darkblue;
	}
	&:disabled {
		background: $lightgrey;
		border-color: $darkwhite;
	}
}

input[type="checkbox"],
input[type="radio"],
input[type="range"] {
	box-shadow: none;
}

input[type="radio"] {
	-webkit-appearance: radio;
	border-radius: 50%;
	padding: 0px;
	outline-offset: 0;
	border: 1px solid #4c4c4c;
	&:checked {
		background: rgba(0, 0, 0, 0.8);
		border-color: rgba(255, 255, 255, 0.0);
	}
	&:checked:disabled {
		opacity: 0.4;
		background: rgba(0, 0, 0, 0.8);
	}
}

input[type="file"] {
	border: 0;
	padding-left: 0;
	padding-right: 0;
	box-shadow: none;
}

input[type="submit"],
input[type="button"] {
	-webkit-appearance: none;
	border-radius: 3px;
}

input[type="submit"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner {
	border: 0;
}

button {
	@include font-size(1);
	outline: none;
	border-radius: 3px;
	padding: 0.5rem;
	font-weight: bold;
	margin: 0;
}

select {
	@include font-size(1);
}

option {
	@include font-size(1);
}

textarea {
	@include font-size(1);
	box-sizing: border-box;
	outline: none;
	border: 0;
	border-bottom: 2px solid $grey;
	resize: none;
	padding: 0.5rem;
	border-radius: 0;
	transition: border 0.2s ease-in-out;
	&:focus {
		outline: none;
		border-color: $darkblue;
	}
}

// Since apple is insane, make inputs 16px on phones so ios doesn't zoom them when users focus on them
@media screen and (max-width:$smallScreen) {

	input {
		@include font-size(1);
	}

}
