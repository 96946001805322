p,
.p {
	@include font-size(1);
	margin-top: 0.625rem;
	&:first-child{
		margin-top: 0;
	}
}

ol {
	@include font-size(1);
	margin-top: 0.625rem;
	padding-left: 1.25rem;
	&:first-child{
		margin-top: 0;
	}
	li {
		list-style-type: decimal;
	}
	ol {
		margin-top: 0;
		li {
			list-style-type: lower-alpha;
		}
	}
}

ul {
	@include font-size(1);
	margin-top: 0.625rem;
	padding-left: 1.25rem;
	&:first-child{
		margin-top: 0;
	}
	li{
		list-style-type: disc;
	}
	ul {
		margin-top: 0;
		li {
			list-style-type: circle;
		}
	}
}

li {
	@include font-size(1);
}

dl {
	> dt {
		margin-top: 10px;
		text-transform: capitalize;
		&:first-child {
			margin-top: 0;
		}
	}
}

dt {
	@include font-size(1);
	font-weight: bold;
}

dd {
	@include font-size(1);
}

figcaption {
	@include font-size(1);
}