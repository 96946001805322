//new ellevation colors
$ellDarkBlue: #22539f;
$ellDarkBlueActive: #265caf;
$ellDarkBlueDisabled: #8ba4c9;
$ellBackground3: #dfdfdf;
$ellBackground2: #f5f5f5;
$ellGreen: #8ac91e; //29bb9c
$ellGrey: #e7e7e7;
$ellGreyBorder: #cecece;
$ellGreyActive: #f0f0f0;
$ellGreyDisabled: #f2f2f2;
$ellBlack: #222;
$ellOrange: #ee8a34;
$ellOrangeActive: #f2a15a;
$ellOrangeDisabled: #f7c9a1;
$ellOtherGrey: #888;

//colors
$lightwhite: #fff;
$white: #fefefe;
$darkwhite: #ecedee;
$lightgrey: #ebebeb;
$grey: #ccc;
$darkgrey: #d2d5d7;
$lightblack: #666;
$black: #333;
$highlight: #8addf2;
$darkblue: #013954;//was 213a53
$orange: #ff9600;

//pl-specific colors

//pl primary
$plblue: #00b4ff;//4fbcd8
$pllightblue: #83d3e7;

//pl secondary
$plgreen: #68b8a3;//66bf8c

//pl tertiary
$plred: #ed304f;
$plyellow: #f4db11;