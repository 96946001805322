// Main mixins "bucket".
// This file contains any and all mixins for the entire application. If you need to make a mixin, put it here.
//
// No styleguide reference.

// font-size() mixin
// Quickly apply a font size and relevant line-height to an element.
// @param (number) sizeValue - Desired font size in rem units. 1.0 = 1rem = 16px.
// @param (auto) lineHeight - Automatically calculated by taking sizeValue and multiplying it by phi.
//
// No styleguide reference.
@mixin font-size($sizeValue: 1, $lineHeight: $sizeValue * 1.618) {
	font-size: $sizeValue + rem;
	line-height: $lineHeight + rem;
}

// button() mixin
// Applies base rules for displaying an element as a button. There are further modifying classes available. See global/buttons.scss for more details.
//
// No styleguide reference.
@mixin button() {
	display: inline-block;
	position: relative;
	padding: 6px 24px;
	@include font-size(1);
	border-radius: 4px !important;
	text-decoration: none;
	box-sizing: border-box;
	font-weight: normal;
	transition: background-color 0.2s ease-in-out,
		color 0.2s ease-in-out;
}
